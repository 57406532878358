// eslint-disable-next-line no-unused-vars
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import styled from 'styled-components';
import i18n from '../../locales/i18n';

import RootLayout from './RootLayout';
import LayoutTemplate from './LayoutTemplate';

const MainLayoutContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const MainLayoutContentContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 1;
`;


class Layout404 extends RootLayout {
    render() {
        const { children } = this.props;
        return (
            <I18nextProvider i18n={i18n}>
                <MainLayoutContainer>

                    <MainLayoutContentContainer id='outer-container'>
                        <LayoutTemplate>
                            {children}
                        </LayoutTemplate>
                    </MainLayoutContentContainer>

                </MainLayoutContainer>
            </I18nextProvider>
        );
    }
}

export default Layout404;
