import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';

// Layout component
import LocalizedLink from '../components/LocalizedLink';
import Layout from '../components/layouts/Layout404';

const CellError = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  bottom: 50%;
`;

const Title = styled.h1`
   color:white;
   font-size: calc(5px + 8vw);
   font-weight:600;
   margin:0px 0px;
   text-transform:uppercase;
`;

const Text = styled.p`
   color:white;
   font-size:34px;
   margin:5px 0px 75px 0px;
   text-align:center;
`;

const Button = styled(LocalizedLink)`
    border-radius: 50px;
    width: auto;
    padding: 1.1em 2.5em;
    font-size:15px;
    font-weight:400;
    font-family:'Montserrat';
    background-color:white;
    color:#227EE6;
    border:2px solid white;
    cursor:pointer;
    text-decoration:none;


  &:hover {
    background-color:transparent;
    border: 2px solid white;
    color:white;
    height:auto;
  }
`;

const NotFoundPage = () => {
    const { t } = useTranslation('404');

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_ERROR')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_ERROR')}/>
            </Helmet>
            <Layout>
                <CellError>
                    <Title>ERROR 404</Title>
                    <Text>{t('TEXT_OUPS')}</Text>
                    <Button to='home'>{t('BUTTON_GO_BACK_HOME')}
                    </Button>
                </CellError>
            </Layout>
        </React.Fragment>
    );
};

export default NotFoundPage;
